<template>
  <v-layout align-center justify-center row>
    <v-flex xs6>
      <Filters
        title="Filters"
        :filters="filters"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Filters from '../../components/crud/Filters.vue';

export default {
  name: 'FiltersTest',
  components: {
    Filters,
  },
  data() {
    return {
      filters: [
        {
          component: 'dateField',
          label: 'Without value',
          color: 'primary',
        },
        {
          component: 'dateField',
          value: '1988-12-22',
        },
        {
          component: 'numberField',
          label: 'Without value',
          color: 'primary',
        },
        {
          component: 'numberField',
          value: 185,
        },
        {
          component: 'numberField',
          label: 'With validation',
          value: 185,
          validation: {
            min: -10,
            max: 100,
            step: 0.5,
          },
        },
        {
          component: 'numberField',
          label: 'With validation (no max)',
          value: 185,
          validation: {
            min: -10,
            step: 0.5,
          },
        },
        {
          component: 'sliderField',
          label: 'With validation',
          value: 50,
          validation: {
            min: -10,
            max: 100,
            step: 0.5,
          },
        },
        {
          component: 'timeField',
          label: 'With validation',
          value: '13:09',
        },
        {
          component: 'textareaField',
          label: 'With validation',
          value: '13:09',
        },
        {
          component: 'textField',
          label: 'With validation',
          value: '13:09',
        },
      ],
    };
  },
};
</script>
